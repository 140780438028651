<template>
  <div>
    <topic-component :topic="$t('invoice.topic')"/>
    <b-card v-if="checkPermission('list', 'action', 'all-invoices')">
      <b-row>
        <b-col lg="3" md="6" sm="12">
          <b-form-group label-cols="12"
                        :label="$t('common.dateTime')" label-for="input-sm">
            <date-time-range-component v-model="search.date_time"/>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="6" sm="12">
          <select-filter-component
              :label-select="$t('invoice.search.status')"
              :options="optionStatus"
              v-model="search.status"
          />
        </b-col>
        <b-col lg="2" md="6" sm="12">
          <select-filter-component
              :label-select="$t('invoice.search.price_range')"
              :options="optionRange"
              v-model="search.price_range"
          />
        </b-col>
        <b-col lg="2" md="6" sm="12">
          <b-form-group label-cols="12"
                        :label="$t('invoice.list.invoiceNumber')" label-for="input-sm">
            <b-form-input v-model="search.invoice_no"/>
          </b-form-group>
        </b-col>
        <b-col style="margin-top: 35px">
          <button type="button" class="btn waves-effect waves-light btn-gradient" @click="findInvoiceAll">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{ $t('common.perPage') }}</span>
            <div>
              <b-form-select
                  v-model="search.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="findInvoiceAll"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
              responsive
              class="mb-0"
              :items="items"
              :fields="fields"
              :busy="isBusy"
              :empty-text="$t('empty')"
              show-empty
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong> {{ $t('loading') }}</strong>
              </div>
            </template>
            <template #empty="scope">
              <empty-table/>
            </template>
            <template #cell(createdAt)="data">
              {{ changeDateFormat(data.item.createdAt) }}
            </template>
            <template #cell(price_detail.total_price)="data">
              {{
              data?.item?.price_detail?.grand_total ? checkPricePlan(data?.item?.price_detail?.grand_total,
              data?.item?.price_detail?.currency) : checkPricePlan(data?.item?.price_detail?.total_price,
              data?.item?.price_detail?.currency)
              }}
            </template>
            <template #cell(status)="data">
              <b-badge pill :class="`px-2 text-white badge ${colorButton(data.item.status)}`">
                {{ filterValue(invoiceStatusList, data?.item?.status) }}
              </b-badge>
            </template>
            <template #cell(details)="data">
              <div class="d-flex" v-if="data.item.subscription.plan_name">
                {{ 'Payment for plan' }} {{ data.item.subscription.plan_name }}
              </div>
            </template>
            <template #cell(invoice_no)="data">
              {{ data.item.invoice_no ? data.item.invoice_no : '-' }}
            </template>
            <template #cell(billing_no)="data">
              {{ data.item.billing_no ? data.item.billing_no : '-' }}
            </template>
            <template #cell(invoice_type)="data">
              {{ filterValue(invoiceTypeList, 'Subscription') }}
            </template>
            <template #cell(payment_method)="data">
              {{ filterValue(paymentChannelList, data?.item?.payment_method) || '-' }}
            </template>
            <template #head()="{ label, field: { key, sortable }}">
              {{ $t(label) }}
            </template>
            <template #cell(action)="data">
              <i style="color: #0d73d9" class="action-button icon-eye mr-2"
                 @click="viewInvoiceDetail(data?.item?.subscription_id, data?.item?.payment_method)"
                 v-if="checkPermission('view', 'action', 'all-invoices')"></i>
            </template>
          </b-table>
        </b-col>
        <div class="ml-auto">
          <b-pagination
              v-model="search.$skip"
              :total-rows="totalRows"
              :per-page="search.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findInvoiceAll"
          />
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import CreateContact from "@/views/contacts/contact/create";
import packageApi from "@/repository/packageApi";
import _ from "lodash";
import TopicComponent from "@/components/common/topicComponent";
import functionsCommon from "@/common/functions";
import InputComponent from "@/components/common/inputComponent";
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent";
import SelectComponent from "@/components/common/selectComponent";
import masterData from "@/common/masterData.json"
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import moment from "moment";
import functions from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {

  name: "invoiceList",
  data: () => ({
    totalAll: 0,
    search: {
      date_time: [
        new Date(moment(new Date()).format("YYYY-MM-DD") + "T00:00:00"),
        new Date(moment(new Date()).format("YYYY-MM-DD") + "T23:59:59"),
      ],
      price_range: null,
      status: null,
      invoice_no: '',
      $skip: 1,
      $limit: 10,
    },
    invoiceTypeList: masterData.invoiceTypeList,
    paymentChannelList: masterData.paymentChanelList,
    isBusy: false,
    fields: [
      {
        key: "createdAt",
        label: "invoice.list.date",
      },
      {
        key: "invoice_no",
        label: "invoice.list.invoiceNumber",
      },
      {
        key: "billing_no",
        label: "invoice.list.billingNumber",
      },
      {
        key: "invoice_type",
        label: "invoice.list.type",
      },
      {
        key: "details",
        label: "invoice.list.details",
      },
      {
        key: "payment_method",
        label: "invoice.list.channel",
      },
      {
        key: "price_detail.total_price",
        label: "invoice.list.amount",
      },
      {
        key: "status",
        label: "invoice.list.status",
      },
      {
        key: "action",
        label: "invoice.list.action",
        class: 'text-center'
      },
    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
    optionStatus: masterData?.invoiceStatus,
    invoiceStatusList: masterData?.invoiceStatus,
    optionRange: [
      {
        value: 'lte-1000',
        en: "<= 1,000",
        th: "<= 1,000",
        cn: "<= 1,000",
        vn: "<= 1,000",
        id: "<= 1,000",
        my: "<= 1,000"

      },
      {
        value: 'lte-10000',
        en: "<= 10,000",
        th: "<= 10,000",
        cn: "<= 10,000",
        vn: "<= 10,000",
        id: "<= 10,000",
        my: "<= 10,000"
      },
      {
        value: 'lte-100000',
        en: "<= 100,000",
        th: "<= 100,000",
        cn: "<= 100,000",
        vn: "<= 100,000",
        id: "<= 100,000",
        my: "<= 100,000"
      },
      {
        value: 'gte-100000',
        en: ">= 100,000",
        th: ">= 100,000",
        cn: ">= 100,000",
        vn: ">= 100,000",
        id: ">= 100,000",
        my: ">= 100,000"
      }
    ]
  }),
  components: {
    TotalListComponent,
    EmptyTable,
    SelectFilterComponent,
    SelectComponent, DateTimeRangeComponent, InputComponent, TopicComponent, CreateContact
  },
  mounted() {
    this.findInvoiceAll()
    this.optionStatus = _.filter(masterData?.invoiceStatus, item => {
      return item?.value !== 'pre-credit-paid'
    })
  },
  methods: {
    colorButton(data) {
      return functions.colorButtonStatusByText(data)
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    findInvoiceAll() {
      this.items = []
      this.isBusy = !this.isBusy
      packageApi.findInvoiceAll(_.cloneDeep(this.search)).then(response => {
        this.totalAll = response?.total
        this.totalRows = response?.total
        this.items = response?.data
      }).catch(err => {
        throw err
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    viewInvoiceDetail(id, payment) {
      this.$router.push(`/Invoice/View?subscription=${id}&payment=${payment}`).catch(() => {
      })
    },
    changeDateFormat(date) {
      return functionsCommon.changeDateFormat(date)
    },
    addComma(data) {
      return data ? data?.total_price?.toLocaleString() : 0
    },
    resetSearch() {
      this.search = {
        date_time: [
          new Date(moment(new Date()).format("YYYY-MM-DD") + "T00:00:00"),
          new Date(moment(new Date()).format("YYYY-MM-DD") + "T23:59:59"),
        ],
        price_range: null,
        status: null,
        invoice_no: '',
        $skip: 1,
        $limit: 10,
      }
      this.findInvoiceAll()
    },
    checkPricePlan(price = 0, currency) {
      const currency_price = currency.format ? currency?.format?.replace('{PRICE}', '') : ''
      return currency_price + price?.toLocaleString()
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  }
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
